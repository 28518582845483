import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	var mobileMenu = {
        init: function(){
			if ($(window).width() < 768) {
				$('#menu-footer-menu > .menu-item > a').each(function() {
				$(this)
					.on('click', function(e) {
					e.preventDefault();
					$(this).toggleClass('active');
					$(this)
						.next()
						.slideToggle();
					});
				});
			}
		}
	};

    $(function() {
        mobileMenu.init();
    });

    $(window).on("resize orientationchange", function () {
        waitForFinalEvent(function(){
            mobileMenu.init();
        }, 500, 'mobile-menu');
    });	

})(jQuery);