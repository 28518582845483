(function($) {

	if( $('.resources.carousel').length > 0 ) {
		var resourcesCarousel = {
			init: function(){
				$('.resources.carousel').each(function(){
                    const slider = $(this).find('.resources__item-wrapper');
                    const arrowWrapper = $(this).find('.resources__arrow-wrapper');
                    const settings = {
                        infinite: true,
                        dots: false,
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 3000,
                        slidesToShow: 1,
                        slidesToScroll: 1,                        
                        appendArrows : arrowWrapper,
                        mobileFirst: true,
                        responsive: [
                            {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                            }
                        ] 
                    };

					const sl =  slider.slick(settings);

			 	});
				
			}
		};

		$(function() {
			resourcesCarousel.init();
		});


	}
})(jQuery);