(function($) {

	if( $('.team-carousel').length > 0 ) {
		var teamCarousel = {
			init: function(){
				$('.team-carousel').each(function(){
                    const slider = $(this).find('.team-carousel__item-wrapper');
                    const arrowWrapper = $(this).find('.team-carousel__arrow-wrapper');
                    const settings = {
                        infinite: true,
                        dots: false,
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 3000,
                        slidesToShow: 2,
                        slidesToScroll: 1,                        
                        appendArrows : arrowWrapper
                    };

					const sl =  slider.slick(settings);

			 	});
				
			}
		};

		$(function() {
			teamCarousel.init();
		});


	}
})(jQuery);