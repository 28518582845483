import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.carousel-banner').length > 0 ) {
		var bannerSlider = {
			init: function(){
				$('.carousel-banner').each(function(){
                    const slider = $(this).find('.carousel-banner__item-wrapper');
                    const dotsWrapper = $(this).find('.carousel-banner__dots-wrapper');
                    const settings = {
                        infinite: true,
                        dots: true,
                        arrows: false,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        vertical:true,
                        appendDots : dotsWrapper
                        
                    };

					const sl =  slider.slick(settings);

                    var maxHeight = -1;
                    $('.slick-slide').each(function() {
                        if ($(this).height() > maxHeight) {
                            maxHeight = $(this).height();
                        }
                    });
                    $('.slick-slide').each(function() {
                        if ($(this).height() < maxHeight) {
                            $(this).css('margin', Math.ceil((maxHeight-$(this).height())/2) + 'px 0');
                        }
                    });


			 	});
				
			}
		};

		$(function() {
			bannerSlider.init();
		});


	}
})(jQuery);