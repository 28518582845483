jQuery(document).ready(function ($) {
    $('.facilitation-team').each(function () {
        var $facilitationTeam = $(this);
        var $showMoreButton = $facilitationTeam.find('.facilitation-team__item-show-more');
        var $showMoreButtonSpan = $showMoreButton.find('span'); 
        var $readMoreWrapper = $facilitationTeam.find('.facilitation-team__read-more-wrapper');

        // Sembunyikan read-more wrapper di awal
        $readMoreWrapper.hide();

        // Klik tombol show more
        $showMoreButton.on('click', function (e) {
            e.preventDefault();

            // Toggle slide
            $readMoreWrapper.slideToggle(300);

            if ($showMoreButtonSpan.text() === 'See More') {
                $showMoreButtonSpan.text('Show Less');
            } else {
                $showMoreButtonSpan.text('See More');
            }
        });
    });
});