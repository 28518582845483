(function($) {

	if( $('.facilitation-team').length > 0 ) {
		var teamAccordion = {
			init: function(){
				$('.facilitation-team__item').each(function(){
                    $(this).on('click', function() {
						var content = $(this).find('.facilitation-team__item-content-wrapper');

                        $(this).toggleClass('open');
						content.slideToggle('medium');
                    })
			 	});
				
			}
		};

		$(function() {
			teamAccordion.init();
		});


	}
})(jQuery);