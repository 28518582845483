import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {
    var makeSameHeight = {
        init: function(){
            if ( $('.resources').length > 0 ) {
                $('.resources').each(function(){
                    $(this).find('.content-card__title:visible').matchHeight();
                }); 
            }        
        }
    };

    $(function() {
        waitForFinalEvent(function(){
            makeSameHeight.init();
        }, 500, 'same-height-init');
    });

    $(window).on("resize orientationchange", function () {
        waitForFinalEvent(function(){
            makeSameHeight.init();
        }, 1000, 'same-height');
    });

})(jQuery);