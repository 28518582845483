(function($) {

	if( $('.testimonials').length > 0 ) {
		var testiCarousel = {
			init: function(){
				$('.testimonials').each(function(){
                    const slider = $(this).find('.testimonials__item-wrapper');
                    const arrowWrapper = $(this).find('.testimonials__arrow-wrapper');
                    const settings = {
                        infinite: true,
                        dots: false,
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        fade: true,                        
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        // adaptiveHeight: true,                
                        appendArrows : arrowWrapper
                    };

					const sl =  slider.slick(settings);

                    // var maxHeight = -1;
                    // $('.slick-slide').each(function() {
                    //     if ($(this).height() > maxHeight) {
                    //         maxHeight = $(this).height();
                    //     }
                    // });
                    // $('.slick-slide').each(function() {
                    //     if ($(this).height() < maxHeight) {
                    //         $(this).css('margin', Math.ceil((maxHeight-$(this).height())/2) + 'px 0');
                    //     }
                    // });


			 	});
				
			}
		};

		$(function() {
			testiCarousel.init();
		});


	}
})(jQuery);