(function ($) {
	//show mobile menu
	$('.menu-toggle').on('click', function () {
    	$('.mobile-menu__wrapper').addClass('active');
  	});

	//hide mobile menu
  	$('.mobile-menu__close-button, .mobile-menu__outer').on('click', function () {
    	$('.mobile-menu__wrapper').removeClass('active');
    	$('.mobile-menu').removeClass('child-active');
    	$('.sub-menu').hide();
  	});

	$('#mobile-menu').find('.menu-item-has-children').each(function () {
		$(this).find('.sub-menu').each(function(){
			if (!$(this).hasClass('has-back-button')) {
				$(this).prepend('<li class="mobile-menu__back"><a href="#">Back</a></li>');
				$(this).addClass('has-back-button');
			}
		});
		
		$(this)
			.children('a')
			.on('click', function (e) {
				e.preventDefault();
				$(this).parents('ul').addClass('child-active');
				$(this).next('.sub-menu').show();
			});
    });

  	$(document).on('click', '.mobile-menu__back', function () {
	    $(this).parent('.sub-menu').hide();
	    $(this).closest('ul.child-active').removeClass('child-active');
	});
})(jQuery);
