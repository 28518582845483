(function($) {
	if( $('.intro').length > 0 ) {   
        var rotateText = {
			init: function(){   
                    
                $('.intro').each(function(){
                    var time = 0;
                    var items = $(this).find('.intro__revolving-text');
                    var itemsHeight = items.outerHeight();

                    if (items.length > 0 ) {

                        items.height( itemsHeight );

                        items.find('strong').each(function(index){
                        
                            var $this = $(this);
                            setTimeout( function(){                    
                                $this.addClass('show').addClass('animating');   

                                setTimeout( function(){ 
                                    $this.removeClass('animating show'); 
                                }, 3000);
                            }, time);

                            time += 3000;                      


                        });
                        
                        setTimeout( function(){                        
                            rotateText.init();
                        }, time);                    

                    }
                    
                });
        
            }
        };
        $(function() {
			rotateText.init();
		});
	}
})(jQuery);