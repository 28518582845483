import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.logo-carousel').length > 0 ) {
		var logoCarousel = {
			init: function(){
				$('.logo-carousel').each(function(){
                    const slider = $(this).find('.logo-carousel__item-wrapper');
                    const sliderItem = slider.find('.logo-carousel__item');
                    const arrowWrapper = $(this).find('.logo-carousel__arrow-wrapper');
                    const settings = {
                        infinite: true,
                        dots: false,
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        slidesToShow: 2,
                        slidesToScroll: 1,                        
                        appendArrows : arrowWrapper,
                        mobileFirst: true,
                        responsive: [
                            {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                            },
                            {
                            breakpoint: 1079,
                            settings: {
                                slidesToShow: 6,
                                slidesToScroll: 1
                            }
                            }
                        ]                        
                    };

                    slider.slick(settings);

                    if ($(window).width() > 768 && sliderItem.length < 5) {
                        slider.slick('unslick');
                    } else if ( $(window).width() > 1079 && sliderItem.length < 7 ) {
                        slider.slick('unslick');
                    } 


			 	});
				
			}
		};

		$(function() {
			logoCarousel.init();
		});

        $(window).on("resize orientationchange", function () {
            waitForFinalEvent(function(){
                logoCarousel.init();
            }, 500, 'logo-carousel');
        });	

	}
})(jQuery);